import Router from '../router';
import axios from 'axios';
import { Notification } from 'element-ui';

// let  BASE_URL = 'https://wcb-wenjuan.mini.bandaochina.com/';
let BASE_URL = ''
class ResponseError {
  constructor(errcode, errmsg, response) {
    this.errmsg = errmsg || '';
    this.errcode = errcode || -1;
    this.response = response;
    if (response.status !== 401) {
      this.showErrmsg();
    }
  }

  isShowError = false;

  showErrmsg = () => {
    if (this.isShowError) {
      return;
    }
    this.isShowError = true;
    if (this.errmsg) {
      /*notification.error({
          message: this.errmsg,
          // description: `error: ${this.response.config.url}`
      });*/
      Notification.error({
        title: '提醒',
        message: this.errmsg
      })
    }
  };

  response = {};

  errmsg = '';

  errcode = -1;
}

/**
 * 配置request请求时的默认参数
 */
const service = axios.create({
  baseURL: BASE_URL + '/api',
  timeout: 5000, // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
    (config) => {
      // config.headers['access-token'] = localStorage.getItem('token');
      config.data = {
        ...config.data,
        token: localStorage.getItem('token')
      }
      return config;

    },
    (error) => {
      // 发送失败
      throw error;
    },
);

// 响应拦截器
service.interceptors.response.use(
    undefined,
    (error) => {
      console.dir(error)
      if (error && error.response) {
        switch (error.response.status) {
          case 400:
            error.message = '请求错误';
            break;
          case 401:
            error.message = ''; //未授权，请先登录
            Router.replace('/Home');
            break;
          case 403:
            Router.push('/404');
            break;
          case 404:
            error.message = `请求地址出错: ${error.response.config.url}`;
            break;
          case 408:
            error.message = '请求超时';
            break;
          case 500:
            error.message = '服务器内部错误';
            break;
          case 501:
            error.message = '服务未实现';
            break;
          case 502:
            error.message = '网关错误';
            break;
          case 503:
            error.message = '服务不可用';
            break;
          case 504:
            error.message = '网关超时';
            break;
          case 505:
            error.message = 'HTTP版本不受支持';
            break;
          default:
            break;
        }
      }
      return Promise.reject(new ResponseError(error.response?.status, error.message, error.response));
    },
);

const onResponse = (response) => {
  const {errcode, errmsg, code, message} = response.data;
  if (errcode || code) {
    return Promise.reject(new ResponseError(errcode || code, errmsg || message, response));
  }
  return response.data || {};
};

export const catchRespError = (error) => {
  error.showErrmsg();
  return {error};
};

const request = (url, data, options) => service({
  url,
  method: 'post',
  data,
  ...options,
}).then(onResponse);

export default request;

export const requestWithCatchError = (url, data = {}, options) =>
    request(url, data, options)
        .catch(catchRespError);
