<template>
  <div class="home">
      <div class="login">
        <img class="logo" src="../assets/images/u22.png" alt="">
        <div class="logo_word">Powered By WCB</div>
          <el-input
                  placeholder="请输入手机号"
                  class="user_input"
                  type="tel"
                  v-model="phone">
              <img class="user_icon" slot="prefix" src="../assets/images/user.svg" alt="">
          </el-input>
          <el-input
                  placeholder="请输入登录密码"
                  v-model="password"
                  type="password"
          >
              <img class="password_icon" slot="prefix" src="../assets/images/password.svg" alt="">
          </el-input>
          <el-button class="login_btn" type="primary" @click="login">登录</el-button>
      </div>
    <div class="login_bottom">
        <div class="login_txt">Copyright © www.WCB.com, All Rights Reserved.</div>
        <div class="login_txt">WCB随访问卷</div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
    data() {
      return {
          phone:'',
          password:''
      }
    },
    methods: {
        login() {
            this.$request('/Login/login',{account:this.phone,password:this.password}).then( (res) => {
                if(res.errcode == 0) {
                    localStorage.setItem('token', res.data.token);
                    localStorage.setItem('name', res.data.name);
                    this.$router.replace({
                        path:'/'
                    })
                }
            })
        }
    }
}
</script>

<style scoped>
  .home {
    width: 100%;
    height: 100vh;
    background: url("../assets/images/u125_state0.png");
    background-size: 100% 100%;
    position: relative;
  }
  .login {
      width: 300px;
      padding: 0 60px;
      height: 580px;
      background: #fff;
      border-radius: 20px;
      position: absolute;
      left: 0;
      right: 0;
      top: 15%;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
  }
  .logo {
      width: 115px;
      height: 115px;
      margin-top: 40px;
      margin-bottom: 30px;
  }
  .logo_word {
      font-weight: 700;
      font-style: normal;
      font-size: 28px;
      color: #236528;
      margin-bottom: 45px;
  }
  .user_icon {
      width: 24px;
      height: 24px;
      margin-top: 12px;
      margin-right: 10px;
      margin-left: 6px;
  }
  .password_icon {
      width: 20px;
      height: 20px;
      margin-top: 14px;
      margin-left: 6px;
  }
  /deep/.el-input__inner{
      padding-left: 44px;
      height: 50px;
      margin-bottom: 20px;
      line-height: 50px;
  }
  /deep/.el-input--prefix .el-input__inner {
      padding-left: 44px;
  }
  .login_bottom {
    width: 100%;
    position: absolute;
    bottom: 50px;
  }
  .login_txt {
    text-align: center;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    color: #CCCCCC;
    line-height: 24px;
  }
    .login_btn {
        width:100%;
        height:50px;
        font-size: 18px;
        margin-top: 50px;
    }
</style>
