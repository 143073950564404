import Vue from 'vue'
import App from './App.vue'
import router from './router'
import request from './utils/request';
import ElementUI from 'element-ui';
import './assets/element-variables.scss'
import store from './store'
import { Select } from 'ant-design-vue';

Vue.config.productionTip = false
Vue.prototype.$request = request;
Vue.use(ElementUI);
import "ant-design-vue/es/select/style/index.css"
Vue.component('a-select', Select);
Vue.component('a-select-option', Select.Option);
// Vue.use(Select);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
